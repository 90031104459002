<template>
	<vue-tel-input
		v-model="inputValue"
		mode="national"
		:dropdown-options="{
			disabled: true,
			showFlags: true
		}"
		:input-options="{
			id: inputId,
			readonly: true
		}"
		style-classes="phone-display"
		@on-input="valueChanged"
	></vue-tel-input>
</template>

<script>
	import { VueTelInput } from 'vue-tel-input';
	
	export default {
		props: {
			value: String
		},
		data() {
			return {
				inputValue: '',
				inputId: ''
			}
		},
		components: {
			VueTelInput
		},
		created() { // When the component is loaded, set the value of the phone number field to the value from the input property, and generate a random ID for the input element so that we can modify its size later.
			this.inputValue = this.addLeadingPlus(this.value); // The phone number field requires the leading plus to be present in order to set the number value correctly, so add this if it isn't there already.
			this.inputId = this.generateRandomString(16);
		},
		methods: {
			generateRandomString(length) { // Generates a random string of the given length.
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
				
				let string = '';
				for(let i = 0; i < length; i++) {
					string += characters.charAt(Math.floor(Math.random() * characters.length));
				}
				
				return string;
			},
			addLeadingPlus(phoneNumber) { // Adds a leading plus symbol to the given phone number if it isn't there already.
				if(phoneNumber != '' && phoneNumber.charAt(0) != '+') {
					phoneNumber = `+${phoneNumber}`;
				}
				
				return phoneNumber;
			},
			valueChanged(number, phoneObject) { // When the VueTelInput component loads and sets its value, resize the input element based on the length of the formatted phone number.
				const formattedNumber = (number.trim() != '' && phoneObject.valid) ? phoneObject.formatted : number; // If the input value isn't a valid phone number, the it was be displayed as is, so we can use that for our calculation.
				document.getElementById(this.inputId).size = formattedNumber.length;
			}
		}
	}
</script>